import { getDeviceInfo, guid } from '../../utils';


/*
 * INTERNAL REQUESTS
 */

export const clearDepositAddresses = () => ({
    type: 'CLEAR_DEPOSIT_ADDRESSES',
});

export const clearWithdrawalAddresses = () => ({
    type: 'CLEAR_WITHDRAWAL_ADDRESSES',
});

export const reloadWalletActivity = () => ({
    type: 'RELOAD_WALLET_ACTIVITY',
});

export const clearWalletActivity = () => ({
    type: 'CLEAR_WALLET_ACTIVITY',
});

export const clearWithdrawRequest = () => ({
    type: 'CLEAR_WITHDRAW_REQUEST',
});

export const withdrawRequestSent = () => ({
    type: 'WITHDRAW_REQUEST_SENT',
});

/*
 * REST REQUESTS
 */

/*
 * WEBSOCKET REQUESTS
 */

export const getPortfolioInfo = () => ({
    ws: {
        type: 'GET_PORTFOLIO_INFO',
        data: {},
        uid: guid(),
        requireAuthorization: true
    }
});

export const getDepositAddresses = (asset) => {
    return dispatch => {
        return dispatch({
            ws: {
                type: 'GET_DEPOSIT_ADDRESSES',
                data: {
                    asset: asset,
                    addressType: 'DEPOSIT'
                },
                uid: guid(),
                requireAuthorization: true
            }
        });
    };
};

export const getWithdrawalAddresses = (asset) => {
    return dispatch => {
        return dispatch({
            ws: {
                type: 'GET_WITHDRAWAL_ADDRESSES',
                data: {
                    asset: asset,
                    addressType: 'WITHDRAWAL'
                },
                uid: guid(),
                requireAuthorization: true
            }
        });
    };
};

export const requestDepositAddress = (asset, network) => ({
    ws: {
        type: 'REQUEST_DEPOSIT_ADDRESS',
        data: {
            asset: asset,
            network: network,
        },
        uid: guid(),
        requireAuthorization: true
    }
});

export const getWalletActivity = ({asset, page = 0, pageSize = 10, types = [
    'DEPOSIT',
    'WITHDRAWAL',
    'TRADE',
    'ORDER',
]}) => {
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const day = tomorrow.getDate();
    const month = tomorrow.getMonth() + 1; // Month is 0-indexed
    const year = tomorrow.getFullYear();
    const hour = tomorrow.getHours();
    const minute = tomorrow.getMinutes();

    return dispatch => {
        // dispatch({
        //    type: 'CLEAR_WALLET_ACTIVITY',
        // });
        return dispatch({
            ws: {
                type: 'GET_WALLET_ACTIVITY',
                data: {
                    asset,
                    page,
                    pageSize,
                    filter: {
                        startDate: '2015-03-31 20:18',
                        endDate: `${year}-${month}-${day} ${hour}:${minute}`,
                        types
                    },
                },
                uid: guid(),
                requireAuthorization: true
            }
        });
    };
};

export const deleteWithdrawAddress = ({asset, network, address, tag, label, code}) => ({
    ws: {
        type: 'DELETE_WITHDRAW_ADDRESS',
        data: {
            asset,
            network,
            amount: 0,
            address,
            tag,
            code,
            requestSource: 'WEBSITE',
            addressLabel: label
        },
        uid: guid(),
        requireAuthorization: true
    }
});

export const setWithdrawAddress = ({asset, network, address, tag, label, code}) => ({
    ws: {
        type: 'SET_WITHDRAW_ADDRESS',
        data: {
            asset,
            network,
            amount: 0,
            address,
            tag,
            code,
            requestSource: 'WEBSITE',
            addressLabel: label
        },
        uid: guid(),
        requireAuthorization: true
    }
});

export const setPermWithdraw = ({asset, network, address, tag, label, code}) => ({
    ws: {
        type: 'SET_PERM_WITHDRAW',
        data: {
            asset,
            network,
            amount: 0,
            address,
            tag,
            code,
            requestSource: 'WEBSITE',
            addressLabel: label
        },
        uid: guid(),
        requireAuthorization: true
    }
});

export const requestWithdraw = ({asset, network, amount, address, tag, label, code}) => ({
    ws: {
        type: 'REQUEST_WITHDRAW',
        data: {
            asset,
            network,
            amount,
            address,
            tag,
            code,
            requestSource: 'WEBSITE',
            addressLabel: label,
        },
        uid: guid(),
        requireAuthorization: true
    }
});

export function confirmAndProcessWithdrawal(confirmationUuid) {
    // requests with device info must be async
    return async dispatch => {
        dispatch({
            type: 'CONFIRM_WITHDRAW_DEVICE_INFO',
            ws: {
                type: 'CONFIRM_WITHDRAW',
                data: {
                    confirmationUuid: confirmationUuid,
                    deviceInfo: await getDeviceInfo(),
                }
            }
        });
    };
}
