import { guid } from '../../utils';
import { NotificationManager } from 'react-notifications';


/*
 * INTERNAL REQUESTS
 */

export const setPriceField = (price) => ({
    type: 'SET_PRICE_FIELD',
    payload: { price: price, },
});

export const setLastPriceInput = (side, input) => ({
    type: 'SET_LAST_PRICE_INPUT',
    payload: { side: side, input: input, },
});

export const clearOrderBookFeed = () => ({
    type: 'CLEAR_ORDER_BOOK_FEED',
});

export const clearCandlestickFeed = () => ({
    type: 'CLEAR_CANDLESTICK_FEED',
});

export const clearAggTradeFeed = () => ({
    type: 'CLEAR_AGG_TRADE_FEED',
});

/*
 * REST REQUESTS
 */


/*
 * WEBSOCKET REQUESTS
 */

export const submitCancelOrders = (orderIds) => ({
    ws: {
        type: 'CANCEL_ORDER',
        data: {
            orderIds,
        },
        uid: guid(),
        requireAuthorization: true
    }
});

export const submitNewOrder = ({ pair, quantity, price, side, orderType, timeInForce, stopPrice, trailingPercent, code }) => {
    return dispatch => {
        NotificationManager.info(pair + ': ' + orderType.replaceAll('_', ' ') + ' ' + side + ' ' + quantity + '@' + price, 'Order Requested');
        return dispatch({
            ws: {
                type: 'REQUEST_NEW_ORDER',
                data: {
                    pair,
                    amount: quantity,
                    price,
                    side,
                    orderType,
                    timeInForce,
                    stopPrice,
                    trailingStopPercent: trailingPercent,
                    code,
                },
                uid: guid(),
                requireAuthorization: true
            }
        });
    };
};

export const getTradeHistory = ({ pair, side, page = 0, pageSize = 10 }) => {
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const day = tomorrow.getDate();
    const month = tomorrow.getMonth() + 1; // Month is 0-indexed
    const year = tomorrow.getFullYear();
    const hour = tomorrow.getHours();
    const minute = tomorrow.getMinutes();

    return dispatch => {
        dispatch({
            type: 'GET_USER_TRADES_HISTORY',
            ws: {
                type: 'GET_USER_TRADES_HISTORY',
                data: {
                    pair,
                    page,
                    pageSize,
                    filter: {
                        side,
                        startDate: '2015-03-31 20:18',
                        endDate: `${year}-${month}-${day} ${hour}:${minute}`,
                    },
                },
                uid: guid(),
                requireAuthorization: true
            }
        });
    };
};

export const startOrderBookFeed = ({ pair }) => {
    return dispatch => {
        return dispatch({
            ws: {
                type: 'START_ORDER_BOOK_FEED',
                data: {
                    pair,
                },
            }
        });
    };
};

export const stopOrderBookFeed = () => {
    return dispatch => {
        return dispatch({
            ws: {
                type: 'STOP_ORDER_BOOK_FEED',
                data: {
                    pair: 'any',
                },
            }
        });
    };
};

export const startCandlestickFeed = ({ pair, interval = '5m'}) => {
    return dispatch => {
        return dispatch({
            ws: {
                type: 'START_CANDLESTICK_FEED',
                data: {
                    pair,
                    interval,
                },
            }
        });
    };
};

export const stopCandlestickFeed = () => {
    return dispatch => {
        return dispatch({
            ws: {
                type: 'STOP_CANDLESTICK_FEED',
                data: {
                    pair: 'any',
                },
            }
        });
    };
};

export const startTickerFeed = ({ pair }) => {
    return dispatch => {
        return dispatch({
            ws: {
                type: 'START_TICKER_FEED',
                data: {
                    pair,
                },
            }
        });
    };
};

export const stopTickerFeed = () => {
    return dispatch => {
        return dispatch({
            ws: {
                type: 'STOP_TICKER_FEED',
                data: {
                    pair: 'any',
                },
            }
        });
    };
};

export const startAggTradeFeed = ({ pair }) => {
    return dispatch => {
        return dispatch({
            ws: {
                type: 'START_AGG_TRADE_FEED',
                data: {
                    pair,
                },
            }
        });
    };
};

export const stopAggTradeFeed = () => {
    return dispatch => {
        return dispatch({
            ws: {
                type: 'STOP_AGG_TRADE_FEED',
                data: {
                    pair: 'any',
                },
            }
        });
    };
};
